import "./project-gallery.styles.scss";
import Button from "../../components/button/button.component";
import React from "react";

class IndividualTag extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="technology">
        <div className="individual-tag">{this.props.tech}</div>
        <div className="individual-desc">{this.props.desc}</div>
      </div>
    );
  }
}

const ProjectGallery = () => {
  return (
    <div className="outer-wrapper">
      <div className="top-container">
        <div className="image-and-cta">
          <a href="https://spectacular-dango-c9dcce.netlify.app/">
            <img className="imgd" src="/newplog.png" />
          </a>

          <div className="cta">
            <a href="https://spectacular-dango-c9dcce.netlify.app/">
              <Button tx="View app" buttonType="codeview" />
            </a>
            <a href="https://github.com/liamnpynz/plogger">
              <Button tx="View code" buttonType="outline" />
            </a>
          </div>
        </div>

        <div className="description-container">
          <div className="project-title">Workout Logger</div>
          <div className="project-description">
            <p>
              This workout tracking app was a project to continue to build my
              React and database skills (and was a project to also be used!). My
              goals here were to a) learn lots, b) to build a workout logging
              web app that was super easy to use, fast, and which looked good,
              and c) to have something I could continue to work on and improve
              over time.
            </p>

            <br />
            <p>
              The name PPLogger is based on the popular Push-Pull-Legs programme
              well recommended online (particuarly Reddit). I got started by
              building some plans in my favourite software of all (Powerpoint)
              before I started to code this up.
            </p>
            <br />

            <p>
              I wanted to stay away from tutorials as much as possible and to
              practice things such as:
            </p>
            <ul>
              <li>Splitting my code into clean, reusable components</li>
              <li>
                Connecting the front-end (React) with any back-end/APIs
                (Firestore)
              </li>
              <li>
                Using basic data structures for storing workout data, and
                building in some basic logic when the user entered their data -
                logic that made sense and felt easy
              </li>
              <li>
                Including things I wanted to get good at using, such as Firebase
                auth because of it's ease-of-use for users, and using
                Material-UI components because people are familiar with them
              </li>
            </ul>
            <br />
            <p>
              This project was almost entirely for learning but it is available
              online for anyone to use, and I am using it myself. I've got lots
              of ideas for future functionality to add, and am thinking about
              converting it over to React Native to learn that language too.
              Because learning was my main goal, I wrote down most of the
              mistakes I faced (and learning I did) during the process to
              continue improving. Please feel free to check out the app and
              provide any feedback.
            </p>
            <div className="tagstechs-container">
              <IndividualTag
                tech="React"
                desc="Main front-end framework - predominantly used hooks"
              />
              <IndividualTag tech="Firebase" desc="Authentication/storage" />
              <IndividualTag tech="NoSQL" desc="Database type" />
              <IndividualTag tech="SCSS" desc="Styling" />
              <IndividualTag tech="Material-UI" desc="User interface" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectGallery;
