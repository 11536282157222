import "./nav.styles.scss";
import { Outlet } from "react-router-dom";
import EmailButton from "../../components/emailbutton/emailbutton.component";
import LPYLogo from "../../components/lpy-logo/lpy-logo.component";
import NavControls from "../../components/nav-controls/nav-controls.component";
import NavDropdown from "../../components/nav-dropown/nav-dropdown.component";
import EmailDropdown from "../../components/email-dropdown/email-dropdown.component";
import { Link } from "react-router-dom";
import { useRef, useEffect, useContext } from "react";
import { NavContext } from "../../context/nav.context";

const AlertOutsideClicks = (navref) => {
  const navContext = useContext(NavContext);
  const { navIsOpen, setNavIsOpen } = navContext;
  useEffect(() => {
    const clickHandler = (event) => {
      if (navref.current && !navref.current.contains(event.target)) {
        setNavIsOpen(false);
      }
    };
    document.addEventListener("mousedown", clickHandler);
    return () => {
      document.removeEventListener("mousedown", clickHandler);
    };
  }, [navref]);
};

const Nav = () => {
  const navref = useRef(null);
  AlertOutsideClicks(navref);
  return (
    <>
      <div className="totalContainer">
        <div ref={navref} className="nav-bar">
          <Link to="/">
            <LPYLogo />
          </Link>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Nav;
