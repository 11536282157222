import { createContext } from "react";
import { useState } from "react";

export const NavContext = createContext({
    navIsOpen: false,
    setNavIsOpen: () => {}
});


export const NavProvider = ({children}) => {
    const [navIsOpen, setNavIsOpen] = useState(false);
    const value = { navIsOpen, setNavIsOpen };

    return (
        <NavContext.Provider value={value}>
            {children}
        </NavContext.Provider>
    )
}

