import { createContext } from "react";
import { useState } from "react";

export const EmailContext = createContext({
    emailIsOpen: false,
    setEmailIsOpen: () => {}
});

export const EmailProvider = ({children}) => {
    const [emailIsOpen, setEmailIsOpen] = useState(false);
    const value = { emailIsOpen, setEmailIsOpen };

    return (
        <EmailContext.Provider value={value}>
            {children}
        </EmailContext.Provider>
    )
}