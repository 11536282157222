import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nav from "./routes/nav/nav.component";
import Home from "./components/home/home.component";
import ProjectGallery from "./routes/project-gallery/project-gallery.component";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Nav />}>
        
        
      </Route>
    </Routes>
  );
}

export default App;
