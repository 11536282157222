import "./lpy-logo.styles.scss";

const LPYLogo = () => {
  return (
    <div className="lpy-logo">
      Liam Platt-Young<span className="dot">.</span>
    </div>
  );
};

export default LPYLogo;
