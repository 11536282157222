import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.scss';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import { NavProvider } from './context/nav.context';
import { EmailProvider} from './context/email.context';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<React.StrictMode>
    <Router>
      <NavProvider>
           <EmailProvider>
               <App />
          </EmailProvider>
    </NavProvider>
  </Router>
</React.StrictMode>
);
